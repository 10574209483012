"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-wrap kiwi-theme-bg",
    class: {
      'kiwi-wrap--statebrowser-drawopen': _vm.stateBrowserDrawOpen,
      'kiwi-wrap--monospace': _vm.$state.setting('useMonospace'),
      'kiwi-wrap--touch': _vm.$state.ui.is_touch
    },
    attrs: {
      "data-activebuffer": _vm.buffer ? _vm.buffer.name.toLowerCase() : ''
    },
    on: {
      "click": _vm.emitDocumentClick,
      "!paste": function paste($event) {
        return _vm.emitBufferPaste.apply(null, arguments);
      }
    }
  }, [!_vm.hasStarted || !_vm.fallbackComponent && _vm.networks.length === 0 ? [_c(_vm.startupComponent, {
    tag: "component",
    on: {
      "start": _vm.startUp
    }
  })] : [_c('state-browser', {
    attrs: {
      "networks": _vm.networks,
      "sidebar-state": _vm.sidebarState
    }
  }), _c('div', {
    staticClass: "kiwi-workspace",
    class: {
      'kiwi-workspace--disconnected': _vm.network && _vm.network.state !== 'connected'
    },
    on: {
      "click": function click($event) {
        _vm.stateBrowserDrawOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "kiwi-workspace-background"
  }), !_vm.activeComponent && _vm.network ? [_c('container', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    },
    scopedSlots: _vm._u([_vm.mediaviewerOpen ? {
      key: "before",
      fn: function fn() {
        return [_c('media-viewer', {
          staticClass: "kiwi-main-mediaviewer",
          attrs: {
            "url": _vm.mediaviewerUrl,
            "component": _vm.mediaviewerComponent,
            "component-props": _vm.mediaviewerComponentProps,
            "is-iframe": _vm.mediaviewerIframe
          },
          on: {
            "close": function close($event) {
              return _vm.$state.$emit('mediaviewer.hide', {
                source: 'user'
              });
            }
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }), _vm.buffer.show_input ? _c('control-input', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }) : _vm._e()] : !_vm.activeComponent ? _c(_vm.fallbackComponent, _vm._b({
    tag: "component"
  }, 'component', _vm.fallbackComponentProps, false)) : _c(_vm.activeComponent, _vm._b({
    tag: "component"
  }, 'component', _vm.activeComponentProps, false))], 2)], _c('svg', {
    staticStyle: {
      "position": "fixed",
      "width": "0",
      "height": "0"
    },
    attrs: {
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('clipPath', {
    attrs: {
      "id": "kiwi-avatar-clip"
    }
  }, [_c('circle', {
    attrs: {
      "r": "50",
      "cx": "50",
      "cy": "50"
    }
  })]), _c('mask', {
    attrs: {
      "id": "kiwi-avatar-mask"
    }
  }, [_c('rect', {
    attrs: {
      "width": "100",
      "height": "100",
      "fill": "#fff"
    }
  }), _c('circle', {
    attrs: {
      "r": "14",
      "cx": "50",
      "cy": "0",
      "transform": "rotate(45 50 50)"
    }
  })])])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;